/* eslint-disable max-lines */
import type { FilterView } from "@prisma/client";
import type { WritableDraft } from "immer/dist/internal";
import { findIndex, isEqual } from "lodash";
import create from "zustand";
import { persist } from "zustand/middleware";

import type { AndFilters, Filters, FilterValue } from "~/lib/filters";
import { isAndFilter } from "~/lib/filters";
import { immer } from "~/lib/zustand";

import useAnalytics from "./useAnalytics";

export type IndexTab =
  | "impactRating"
  | "biodiversityImpact"
  | "climateImpact"
  | "waterImpact"
  | "yieldImpact";

export type Campaign = {
  name: string;
  code: string;
  scored?: boolean;
};

interface VizState {
  showGlobalDistribution: boolean;
  groupByExploitation: boolean;
  showDynamic: boolean;
  filters: Filters;
  filterViewId: number | null;
  filterViewEditing: number | null;
  campaignFilter: string | null;
  chosenCampaign: Campaign | null;
  comparison: null | {
    filtersOne: Filters | null; // a filter config or null to select all
    filtersTwo: Filters | null; // a filter view id or null to select all
  };
  comparisonViewIds: null | {
    segmentOne: number | null;
    segmentTwo: number | null;
  };
  impactSegmentId: number | null;
  sourcingFilterFollowed: boolean;
}

interface VizStateActions {
  setShowGlobalDistribution: (s: boolean) => void;
  setGroupByExploitation: (b: boolean) => void;
  setShowDynamic: (s: boolean) => void;
  setFilters: (f: Filters) => void;
  addFilter: (k: string, ...values: FilterValue[]) => void;
  addAndFilter: (k: string, v?: FilterValue[]) => void;
  removeFilter: (k: string, ...values: FilterValue[]) => void;
  removeAndFilter: (k: string, v?: FilterValue[]) => void;
  setFilterView: (v: FilterView | null) => void;
  setFilterViewEditing: (b: number | null) => void;
  setCampaignFilter: (c: string | null) => void;
  setChosenCampaign: (campaign: Campaign | null) => void;
  resetCampaignFilter: () => void;
  resetFilters: () => void;
  reset: () => void;
  setComparison: (filtersOne: Filters | null, segmentTwo: Filters | null) => void;
  setComparisonViews: (viewOne: number | null, viewTwo: number | null) => void;
  quitComparison: () => void;
  setImpactSegmentId: (id: number | null) => void;
  setSourcingFilterFollowed: (b: boolean) => void;
}

type VizStateWithActions = VizState & VizStateActions;

const initialState: VizState = {
  showGlobalDistribution: false,
  groupByExploitation: true,
  showDynamic: false,
  filters: {},
  filterViewId: null,
  filterViewEditing: null,
  campaignFilter: null,
  chosenCampaign: null, // @todo: chosenCampaign to replace campaignFilter
  comparison: null,
  comparisonViewIds: null,
  impactSegmentId: null,
  sourcingFilterFollowed: false,
};

const useVizState = create<VizStateWithActions>(
  persist(
    immer((mutate) => ({
      ...initialState,

      setShowGlobalDistribution: (show: boolean) =>
        mutate((state) => {
          useAnalytics.getState().trackEvent("viz:toggle-show-global-distribution", {
            show,
          });
          state.showGlobalDistribution = show;
          state.showDynamic = false;
        }),

      setGroupByExploitation: (group: boolean) =>
        mutate((state) => {
          useAnalytics.getState().trackEvent("viz:toggle-group-by-exploitation", {
            group,
          });
          state.groupByExploitation = group;
        }),

      setShowDynamic: (show: boolean) =>
        mutate((state) => {
          useAnalytics.getState().trackEvent("viz:toggle-show-dynamic", {
            show,
          });
          state.showDynamic = show;
          state.showGlobalDistribution = false;
        }),

      setFilters(filters: Filters) {
        mutate((state) => {
          useAnalytics.getState().trackEvent("filters:set", { filters });
          state.filters = filters;
          state.filterViewId = null;
          state.comparison = null;
        });
      },

      addFilter(key: string, ...values: FilterValue[]) {
        mutate((state) => {
          if (values.length === 0) {
            if (!state.filters[key]) {
              state.filters[key] = [];
            }
            return;
          }

          useAnalytics.getState().trackEvent("filters:add", { key, values });

          if (!state.filters[key]) {
            state.filters[key] = values;
          } else if (isAndFilter(state.filters[key])) {
            throw new Error("Invalid filter type");
          } else {
            for (const value of values) {
              const filter = state.filters[key] as FilterValue[];
              // verify the value is not already in the filter
              if (!filter.some((filter) => isEqual(filter, value))) {
                filter.push(value);
              }
            }
          }

          state.filterViewId = null;
          state.comparison = null;
        });
      },

      addAndFilter(key: string, values?: FilterValue[]) {
        mutate((state) => {
          if (values === undefined) {
            if (!state.filters[key]) {
              state.filters[key] = [];
            }
            return;
          }

          useAnalytics.getState().trackEvent("filters:add", { key, values });
          if (!state.filters[key]) {
            // No need to have AND filters with only one entry
            state.filters[key] = values;
          } else if (isAndFilter(state.filters[key])) {
            const filter = state.filters[key] as WritableDraft<AndFilters>;
            // verify the value is not already in the filter
            if (!filter["AND"].some((filterValues) => isEqual(filterValues, values))) {
              filter["AND"].push(values);
            }
          } else {
            // transform into AND filter
            const filter = state.filters[key] as FilterValue[];
            state.filters[key] = {
              AND: [filter, values],
            };
          }

          state.filterViewId = null;
          state.comparison = null;
        });
      },

      removeFilter(key: string, ...values: FilterValue[]) {
        mutate((state) => {
          useAnalytics.getState().trackEvent("filters:remove", { key, values });
          if (state.filters[key]) {
            if (values.length === 0) {
              delete state.filters[key];
              return;
            }

            if (isAndFilter(state.filters[key])) {
              throw new Error("Invalid filter type");
            } else {
              const filter = state.filters[key] as FilterValue[];

              for (const value of values) {
                const index = findIndex(filter, (filter) => isEqual(filter, value));
                if (index >= 0) {
                  filter.splice(index, 1);
                  if (filter.length === 0) {
                    delete state.filters[key];
                  }
                }
              }
            }
          }
          state.filterViewId = null;
        });
      },

      removeAndFilter(key: string, values?: FilterValue[]) {
        mutate((state) => {
          useAnalytics.getState().trackEvent("filters:remove", { key, values });
          if (state.filters[key]) {
            if (!values) {
              delete state.filters[key];
            } else if (isAndFilter(state.filters[key])) {
              const filter = state.filters[key] as WritableDraft<AndFilters>;
              const index = findIndex(filter["AND"], (filterValues) =>
                isEqual(filterValues, values)
              );
              if (index >= 0) {
                filter["AND"].splice(index, 1);
                if (filter["AND"].length === 0) {
                  delete state.filters[key];
                } else if (filter["AND"].length === 1) {
                  // Transform into normal filter
                  state.filters[key] = filter["AND"][0];
                }
              }
            } else if (isEqual(state.filters[key], values)) {
              delete state.filters[key];
            }
          }
          state.filterViewId = null;
        });
      },

      setFilterView(view: FilterView | null) {
        mutate((state) => {
          if (view) {
            useAnalytics.getState().trackEvent("filter-view:set", { view });
            state.filterViewId = view.id;
            state.filters = view.filters as Filters;
            state.comparison = null;
            state.comparisonViewIds = null;
          } else {
            useAnalytics.getState().trackEvent("filter-view:reinitialize");
            state.filterViewId = null;
          }
        });
      },

      setFilterViewEditing(id: number | null) {
        mutate((state) => {
          state.filterViewEditing = id;
        });
      },
      setChosenCampaign(campaign: Campaign | null) {
        mutate((state) => {
          state.chosenCampaign = campaign;
        });
      },
      setCampaignFilter(campaignName: string | null) {
        // TODO: WHEN REMOVED, FIRE THE SAME EVENT FOR setChosenCampaign
        mutate((state) => {
          useAnalytics.getState().trackEvent("campaign:set-filter", { campaignName });
          state.campaignFilter = campaignName;
        });
      },

      resetCampaignFilter() {
        mutate((state) => {
          useAnalytics.getState().trackEvent("campaign:reset-filter");
          state.campaignFilter = null;
        });
      },

      resetFilters() {
        mutate((state) => {
          state.filters = {};
          state.filterViewId = null;
          state.comparison = null;
          state.comparisonViewIds = null;
        });
      },

      setComparison(filtersOne: Filters | null, filtersTwo: Filters | null) {
        mutate((state) => {
          useAnalytics.getState().trackEvent("comparisons:set");
          state.comparison = {
            filtersOne,
            filtersTwo,
          };

          state.filters = {};
          state.filterViewId = null;
        });
      },

      setComparisonViews(segmentOne: number | null, segmentTwo: number | null) {
        mutate((state) => {
          useAnalytics.getState().trackEvent("comparisons:set");
          state.comparisonViewIds = {
            segmentOne,
            segmentTwo,
          };

          state.filters = {};
          state.filterViewId = null;
        });
      },

      quitComparison() {
        mutate((state) => {
          useAnalytics.getState().trackEvent("comparisons:quit");
          state.comparison = null;
        });
      },

      setImpactSegmentId(id: number | null) {
        mutate((state) => {
          if (id !== null) {
            useAnalytics.getState().trackEvent("impact-segment:set-id", { id });
          } else {
            useAnalytics.getState().trackEvent("impact-segment:reinitialize");
          }
          state.impactSegmentId = id;
        });
      },

      setSourcingFilterFollowed(sourcingFilterFollowed: boolean) {
        mutate((state) => {
          state.sourcingFilterFollowed = sourcingFilterFollowed;
        });
      },

      reset() {
        mutate((state) => {
          Object.assign(state, initialState);
        });
      },
    })),
    { name: "graphState" }
  )
);

export default useVizState;
