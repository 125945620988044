import { groupBy, map } from "lodash";

import { filterTail } from "../helpers";
import type { Rating } from "../rated";

interface Sample {
  rating?: Partial<Rating> | null;
}

interface Hsu {
  samples?: Sample[];
}

interface Mission {
  createdAt?: Date;
  campaignName?: string;
  campaign?: { name: string };
  hsus: Hsu[];
}

interface Options {
  campaignFilter?: string | null;
}

const mergeMissionsByCampaign = (missions: Mission[]) => {
  const missionsByCampaign = groupBy(
    missions,
    ({ campaign, campaignName }) => campaign?.name ?? campaignName
  );
  return map(missionsByCampaign, (missions, campaignName) => ({
    ...missions[0],
    campaignName,
    hsus: missions.flatMap(({ hsus }) => hsus),
  }));
};

export const getLatestDisplayableMission = <T extends { missions: Mission[] }>(
  exploitation: T,
  { campaignFilter }: Options = {}
): T["missions"][number] | undefined => {
  const missions = mergeMissionsByCampaign(exploitation.missions);
  const scoredMissions = missions.filter(
    ({ hsus }) =>
      hsus.length > 0 &&
      hsus.every(({ samples }) => samples?.length && samples?.every?.(({ rating }) => !!rating))
  );

  const filteredMissions = campaignFilter
    ? filterTail(scoredMissions, ({ campaignName = "" }) => campaignName <= campaignFilter)
    : scoredMissions;

  // take the latest scored mission, if exists
  if (filteredMissions.length) {
    return filteredMissions[filteredMissions.length - 1];
  } else if (!campaignFilter) {
    return missions[missions.length - 1];
  } else {
    return undefined;
  }
};
