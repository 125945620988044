import { filterHead } from "./helpers";

interface Options {
  allowEmptyCampaignValue: boolean;
}

interface WithCampaign {
  campaign?: { name: string } | null;
  campaignName?: string | null;
}

function applyCampaignFilter<T extends WithCampaign>(
  array: T[],
  campaignFilter?: string | null,
  options?: Options
): T[];
function applyCampaignFilter(
  array: undefined,
  campaignFilter?: string | null,
  options?: Options
): undefined;
function applyCampaignFilter<T extends WithCampaign>(
  array: T[] | undefined,
  campaignFilter?: string | null,
  options?: Options
) {
  const defaultOptions = { allowEmptyCampaignValue: false };
  const { allowEmptyCampaignValue } = {
    ...defaultOptions,
    ...options,
  };

  if (!array || !campaignFilter) {
    return array;
  } else {
    const filtered = filterHead(
      array,
      ({ campaign, campaignName }) =>
        !!(campaign ?? campaignName) && (campaign?.name ?? campaignName)! <= campaignFilter
    );

    if (
      filtered.length > 0 &&
      (allowEmptyCampaignValue ||
        (filtered[0].campaign?.name ?? filtered[0].campaignName) === campaignFilter)
    ) {
      return filtered;
    } else {
      return [];
    }
  }
}

export default applyCampaignFilter;
