/* eslint-disable max-lines */
import { ExtendedVersionEnum, VersionEnum } from "@prisma/client";
import { isNaN, max, round } from "lodash";

import { type ColorType, undefinedRatingColors } from "~/constants/ratingColors";
import config from "~/lib/tailwind";

import { getAllIndicators } from "./genu";

export const allVersions: VersionEnum[] = Object.values(VersionEnum);

interface RatedParcel {
  ratings: {
    version?: VersionEnum | ExtendedVersionEnum;
  }[];
}

export enum RatingMode {
  Integer = "Integer",
  OneDecimal = "OneDecimal",
}

interface UserWithVersion {
  client?: { versions: ExtendedVersionEnum[] } | null;
  farmer?: { versions: ExtendedVersionEnum[] } | null;
}

export const getDependenciesVersion = (
  parcels: RatedParcel[],
  userVersions: ExtendedVersionEnum[] | undefined
) => {
  if (!userVersions || userVersions.length === 0) {
    return ExtendedVersionEnum.none;
  }

  if (userVersions.length === 1) {
    return userVersions[0];
  }

  if (parcels.length === 0) {
    return max(userVersions)!;
  }

  const ratingVersions = new Set(
    parcels.flatMap(({ ratings }) => ratings.map(({ version }) => version))
  );

  const possibleVersions = userVersions.filter((version) => ratingVersions.has(version));
  return max(possibleVersions) ?? max(userVersions)!;
};

export const toRatingsVersions = (user: UserWithVersion | null, profile: "org" | "farmers") => {
  const versions = profile === "org" ? user?.client?.versions : user?.farmer?.versions;
  return versions ?? [ExtendedVersionEnum.none];
};

export const hasLegacyRatings = (user: UserWithVersion | null | undefined, role: string) => {
  // Weird edge case. Remove any invalid states coming in.
  if (!user) {
    return true;
  }

  if (role === "farmers") {
    // If customer has Legacy, then that's all they should have
    return user.farmer?.versions.includes(ExtendedVersionEnum.none);
  } else {
    // If customer has Legacy, then that's all they should have
    return user.client?.versions.includes(ExtendedVersionEnum.none);
  }
};

export interface CampaignScore {
  campaignCode?: string | null;
  campaignName?: string | null;
  parcelCount?: number;
  hidden?: boolean;
  value: number | null;
}

export interface NonNullCampaignScore {
  campaignCode?: string;
  campaignName?: string;
  parcelCount?: number;
  hidden?: boolean;
  value: number;
}

export enum ScoreType {
  Legacy = "Legacy",
  Updated = "Updated",
}

export const HIGH_SCORE = (scoreType: ScoreType) => (scoreType === ScoreType.Legacy ? 60 : 55);

export const LOW_SCORE = (scoreType: ScoreType) => (scoreType === ScoreType.Legacy ? 40 : 37);

export const getRatingColorLegacy = (rating: number) => {
  const rounded = Math.round(rating);
  if (isNaN(rating)) {
    return config.theme.colors.gray;
  } else if (rounded < FAIR_SCORE) {
    return config.theme.colors.red;
  } else if (rounded < GOOD_SCORE) {
    return config.theme.colors.yellow;
  } else {
    return config.theme.colors.lime;
  }
};

export enum ScoreLegacyClass {
  High = "A",
  Medium = "B",
  Low = "C",
  Undefined = "-",
}

export const getLegacyRatingLetter = (value?: number) => {
  if (value === undefined || Number.isNaN(value)) {
    return ScoreLegacyClass.Undefined;
  }

  if (value >= GOOD_SCORE) {
    return ScoreLegacyClass.High;
  } else if (value >= FAIR_SCORE) {
    return ScoreLegacyClass.Medium;
  } else {
    return ScoreLegacyClass.Low;
  }
};

export const OPTIMAL_SCORE = 8;
export const GOOD_SCORE = 6;
export const FAIR_SCORE = 4;
export const DEGRADED_SCORE = 2;

export enum ScoreClass {
  Optimal = "Optimal",
  Good = "Good",
  Fair = "Fair",
  Degraded = "Degraded",
  Critical = "Critical",
  Undefined = "-",
}

export const emptyDistribution = (includeUndefined = false) =>
  Object.values(ScoreClass).reduce(
    (acc, key) => {
      if (includeUndefined || key !== ScoreClass.Undefined) {
        acc[key] = 0;
      }
      return acc;
    },
    {} as Record<ScoreClass, number>
  );

// the strings in the enum do not correspond to the keys used for the colors
const scoreClassToRatingColor = (scoreClass: ScoreClass): string | undefined => {
  if (scoreClass === ScoreClass.Undefined) {
    return undefined;
  }
  return `rating-${scoreClass.toLowerCase()}`;
};

export const getRatingColor = (scoreClass: ScoreClass, colorType: ColorType): string => {
  const ratingColor = scoreClassToRatingColor(scoreClass);
  if (!ratingColor) {
    return undefinedRatingColors[colorType];
  }
  return config.theme.colors[ratingColor][colorType];
};

export const campaignScoresToRoundedCampaignScores = (
  ratings: CampaignScore[],
  mode: RatingMode = RatingMode.Integer
) =>
  ratings.map(({ value, ...rest }) => ({
    ...rest,
    value: roundRating(value, mode),
  }));

export const roundRating = (value?: number | null, mode: RatingMode = RatingMode.OneDecimal) => {
  if (value === undefined || value === null || Number.isNaN(value)) {
    return null;
  }

  if (mode === RatingMode.Integer) {
    return Math.floor(value);
  }

  return round(value, 1);
};

export const getRating = (value?: number | null) => {
  if (value === undefined || value === null || Number.isNaN(value)) {
    return null;
  }

  return Math.min(10, (11 * value) / 100);
};

export const getScoreClass = (rating?: number | null): ScoreClass => {
  if (rating === undefined || rating === null) {
    return ScoreClass.Undefined;
  }

  if (rating >= OPTIMAL_SCORE) {
    return ScoreClass.Optimal;
  } else if (rating >= GOOD_SCORE) {
    return ScoreClass.Good;
  } else if (rating >= FAIR_SCORE) {
    return ScoreClass.Fair;
  } else if (rating >= DEGRADED_SCORE) {
    return ScoreClass.Degraded;
  } else {
    return ScoreClass.Critical;
  }
};

export const getRatingValue = (scores?: CampaignScore[]): number | null | undefined =>
  scores?.[0]?.value;

export const getRatingLatestCampaignCode = (scores?: CampaignScore[]): string | undefined => {
  const res = scores?.find(({ campaignCode }) => !!campaignCode)?.campaignCode;
  return res ?? undefined;
};

export const campaignCodeToName = (campaignCode?: string | null): string | undefined => {
  const matches = campaignCode?.match(/20\d{2}.*$/);
  return matches?.[0];
};

export const toRating = <
  T extends { impactRating: number | null; biodiversityImpact?: number | null },
>(
  datum: T
): T => {
  return {
    ...datum,
    impactRating: getRating(datum.impactRating) ?? null,
    biodiversityImpact: getRating(datum.biodiversityImpact) ?? null,
  };
};

export const toIndicator = <T extends object>(datum: T) => {
  return {
    ...datum,
    ...Object.fromEntries(
      getAllIndicators()
        .filter((indicator) => indicator in datum)
        .map((indicator) => [
          indicator,
          getRating((datum as Record<string, number>)[indicator]) ?? null,
        ])
    ),
  };
};

export const toIndicators = <T extends object>(data: T[]) => {
  return data.map((datum) => toIndicator(datum));
};

export default getRatingColorLegacy;
